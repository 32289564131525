import { Card, CardContent, Container, Grid, Typography } from "@mui/material";

export function Benefits() {
  const benefitsData = [
    {
      title: "Fitness Reinvented",
      description:
        "Say goodbye to boring workouts and hello to Kangoo Jumps! Experience a full-body workout that engages every muscle group while keeping things exciting and enjoyable.",
    },
    {
      title: "Community Connection",
      description:
        "Join our vibrant Kangoo Jumps community, both locally and globally! Connect with fellow enthusiasts online or in person for inspiration and support on your wellness journey.",
    },
    {
      title: "Health & Happiness",
      description:
        "Embrace the joy of movement with Kangoo Jumps. Boost your mood, relieve stress, and enhance your overall well-being. Discover the transformative power of fitness that feels like pure fun.",
    },
    {
      title: "Injury Rehabilitation",
      description:
        "Kangoo Jumps can aid in injury rehabilitation by providing low-impact, joint-friendly exercise that strengthens muscles and promotes flexibility. Up to 80% less impact on the joints.",
    },
    {
      title: "Balance, Mobility, and Coordination",
      description:
        "By jumping on Kangoo boots, you engage your core muscles and challenge your body to become more stable and coordinated. With each class, you'll notice yourself moving with greater ease and grace in everything you do.",
    },
    {
      title: "Weight Management",
      description:
        "Kangoo Jumps can assist in weight management by promoting fat loss through its high-intensity, calorie-burning workouts. Burn up to 1000 calories per hour.",
    },
  ];

  return (
    <Container
      id="benefits"
      maxWidth="lg"
      sx={{
        pt: { xs: 4, sm: 12 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 1, sm: 2 },
      }}
    >
      <Typography component="h2" variant="h4" color="white">
        Why join us?
      </Typography>
      <Grid container spacing={3}>
        {benefitsData.map((benefit, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                color: "rgba(0, 0, 0, 0.87)",
                borderRadius: "15px",
                boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                height: 280,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: 3,
                backdropFilter: "blur(10px)",
                border: "1px solid rgba(255, 255, 255, 0.2)",
                transition: "transform 0.3s, box-shadow 0.3s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 12px 30px rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "rgba(90, 47, 158, 1)",
                    marginBottom: 2,
                  }}
                >
                  {benefit.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  {benefit.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
