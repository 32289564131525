import { Box, Container, Grid, Typography } from "@mui/material";
import Image1 from "../../slider1.jpg";
import Image2 from "../../slider4.jpg";
import Image3 from "../../slider3.jpg";
import Image4 from "../../slider2.jpg";

export function AboutUs() {
  return (
    <Container
      id="aboutus"
      maxWidth="lg"
      sx={{
        pt: { xs: 4, sm: 12 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 1, sm: 2 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="white">
          About us
        </Typography>
      </Box>
      <Grid
        container
        spacing={4}
        alignItems="start"
        justifyContent="space-between"
      >
        {/* Right Image Grid */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            {[Image1, Image2, Image3, Image4].map((image, index) => (
              <Grid item xs={6} key={index}>
                <Box
                  sx={{
                    backgroundImage: `url('${image}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: 2,
                    height: 200, // Adjust as needed
                  }}
                ></Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/* Text Content */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" sx={{ mb: 4, lineHeight: 1.8 }}>
            Home to the fun Kangoo Jumps classes, we’re here to help you get
            fit, healthy, and energized together! We believe in creating an
            inclusive space where everyone can feel empowered to reach their
            fitness goals while genuinely enjoying the process.
          </Typography>
          <Typography variant="body1" sx={{ mb: 4, lineHeight: 1.8 }}>
            Our studio is designed to inspire energy and connection, bringing
            together a vibrant community of fitness enthusiasts who motivate and
            support one another. Whether you’re a seasoned athlete or just
            starting your journey, Hoppenhagen offers a welcoming environment
            that combines effective workouts with a sense of joy and
            camaraderie.
          </Typography>
          <Typography variant="body1" sx={{ mb: 4, lineHeight: 1.8 }}>
            Our Kangoo Jumps workouts perfectly embody our philosophy: a
            low-impact, high-energy class that’s as exciting as it is
            transformative. But beyond any single workout, Hoppenhagen is about
            fostering a love for movement, health, and connection that keeps you
            coming back for more.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
