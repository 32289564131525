import { Box, Grid, Typography, Card, CardContent } from "@mui/material";

export function ClassSchedule() {
  const schedule = [
    {
      day: "Wednesday",
      time: "17:00 - 18:00",
      title: "Kangoo Jumps",
    },
    {
      day: "Sunday",
      time: "10:00 - 11:00",
      title: "Kangoo Jumps",
    },
  ];

  return (
    <>
      <Box
        id="classSchedule"
        sx={{
          pt: { xs: 1, sm: 2 },
          mt: { xs: 4, sm: 12 },
          width: "100%", // Ensure full width
          backgroundColor: "#fafafa", // Background color
          margin: 0, // Remove any margin
          padding: 0, // Remove any padding
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center content horizontally
          justifyContent: "center", // Center content vertically
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="rgba(90, 47, 158, 1)"
          fontWeight="bold"
          sx={{ mb: 3, textAlign: "center" }}
        >
          Class Schedule
        </Typography>

        <Grid
          container
          spacing={4}
          sx={{ width: "100%", paddingX: 2, mb: 2, alignItems: "center" }}
        >
          {/* Workout Descriptions */}
          <Grid item xs={12} md={6}>
            <Box sx={{ flexDirection: "row" }}>
              {schedule.map((item, index) => (
                <Card
                  key={index}
                  sx={{
                    mb: 3,
                    backgroundColor: "#fff",
                    borderRadius: 2,
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#5a2f9e" }}
                    >
                      {item.day}: {item.time}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: "#555" }}>
                      {item.title}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Grid>

          {/* Class Descriptions and Map (Two Columns) */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                color: "#333",
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", mb: 2, color: "#5a2f9e" }}
              >
                Hoppenhagen's Kangoo Jumps® Class Description
              </Typography>
              <Typography variant="body1" sx={{ lineHeight: 1.8, mb: 2 }}>
                The Kangoo Jumps® class combines high-intensity cardio, strength
                training, and lots of fun, all while wearing specially designed
                rebound shoes that let you bounce like a kangaroo. For comfort,
                wearing long socks with the boots is a must. For helping you
                burn calories, build core strength, and boost endurance, the
                class features dynamic Kangoo Jumps/aerobics moves performed to
                an energising playlist that keeps you motivated and engaged.
                Don’t forget to bring a yoga mat, as we sometimes finish with
                core or glute exercises after class.
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontStyle: "italic", color: "#777" }}
              >
                Disclaimer: By participating in activities at Hoppenhagen, you
                acknowledge that the company is not liable for any injuries. You
                consent to being photographed or recorded during the class
                unless you request otherwise. If you wish to opt out, please
                notify us, and we will remove any images or recordings. Your
                class registration confirms your acceptance of these terms.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          overflow: "hidden",
          width: "100%",
          height: "100%",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.03)",
          },
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2250.041022188776!2d12.545833276401952!3d55.6708867982981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465253c1a9bb784d%3A0x577704fad0fdd1c6!2sHoppenhagen%20Fitness%20Club!5e0!3m2!1sen!2sdk!4v1735649878476!5m2!1sen!2sdk"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          // loading="lazy"
          title="Location Map"
        ></iframe>
      </Box>
    </>
  );
}
