import { Box, Typography, Button } from "@mui/material";

export function Hero() {
  return (
    <Box
      sx={{
        top: 0,
        position: "relative",
        width: "100%",
        height: "100vh", // Use full viewport height
        overflow: "hidden", // Ensures no scrollbars appear
      }}
    >
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: "absolute", // Position the video absolutely within the container
          top: 0,
          left: 0,
          width: "100%", // Make the video take up the full width
          height: "100%", // Make the video take up the full height
          objectFit: "cover", // Ensures the video covers the full area without distortion
        }}
      >
        <source src={"./coverVideo2.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay Content */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          textAlign: "center",
          px: 2, // Padding for small screens
          background: "rgba(0, 0, 0, 0.4)", // Optional overlay for better text visibility
        }}
      >
        <Typography
          component="h1"
          variant="h3"
          sx={{
            fontWeight: "bold",
            mb: 3,
          }}
        >
          Welcome to Hoppenhagen!
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontSize: "1.2rem",
            lineHeight: 1.8,
            mb: 2,
          }}
        >
          Where fun meets fitness! Kangoo Jumps is now in Copenhagen, Denmark!
          Intensive, fun, and fat-burning workouts designed to get you
          energized!
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontSize: "1.1rem",
          }}
        >
          Come and jump with us!
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{
            mt: 3,
            px: 4,
            py: 1.5,
            fontWeight: "bold",
            color: "#5a2f9e",
            backgroundColor: "#a2ffef",
            "&:hover": { backgroundColor: "#b5ffef" },
          }}
          onClick={() => {
            const pricingSection = document.getElementById("pricing");
            if (pricingSection) {
              pricingSection.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          Book trial session
        </Button>
      </Box>
    </Box>
  );
}
